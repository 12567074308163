import {lazy, FC, Suspense} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper';
import {MenuTestPage} from '../pages/MenuTestPage';
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import {WithChildren} from '../../_metronic/helpers';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
import {getReferer} from '../modules/auth/core/AuthHelpers';

const PrivateRoutes = () => {
  const ClientsPage = lazy(() => import('../modules/apps/clients/ClientsPage'));
  const DealsPage = lazy(() => import('../modules/apps/deals/DealsPage'));
  const TasksPage = lazy(() => import('../modules/apps/tasks/TasksPage'));
  const ExchangeRatePage = lazy(() => import('../modules/apps/exchange-rates/ExchangeRatesPage'));
  const InvoicePage = lazy(() => import('../modules/apps/invoices/InvoicesPage'));
  const RefundPage = lazy(() => import('../modules/apps/refunds/RefundsPage'));

  const PaymentPlanOrdersPage = lazy(
    () => import('../modules/apps/payment-plan-orders/PaymentPlanOrdersPage')
  );

  const RemindersPage = lazy(() => import('../modules/apps/reminders/RemindersPage'));

  const UsersPage = lazy(() => import('../modules/apps/users/UsersPage'));

  const TravelDealsPage = lazy(
    () => import('../modules/apps/website/flight-deals/FlightDealsPage')
  );

  const ToursPage = lazy(() => import('../modules/apps/website/tours/ToursPage'));

  const BlogArticlesPage = lazy(
    () => import('../modules/apps/website/blog-articles/BlogArticlesPage')
  );

  const FlightDealOrdersPage = lazy(
    () => import('../modules/apps/orders/flight-deal-orders/FlightDealOrdersPage')
  );

  const TourOrdersPage = lazy(() => import('../modules/apps/orders/tour-orders/TourOrdersPage'));

  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));

  const referer = getReferer();

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={referer ? referer : '/dashboard'} />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        {/* <Route path='payment_installment/:productId' element={<PaymentInstallmentWrapper />} />

        <Route path='order/:orderId' element={<CustomerPaymentWrapper />} />

        <Route path='payment_product' element={<ProductCreationWrapper />} />

        <Route path='order/create' element={<CreateOrderWrapper />} /> */}

        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='apps/clients/*'
          element={
            <SuspensedView>
              <ClientsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/deals/*'
          element={
            <SuspensedView>
              <DealsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/tasks/*'
          element={
            <SuspensedView>
              <TasksPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/reminders/*'
          element={
            <SuspensedView>
              <RemindersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/exchange-rates/*'
          element={
            <SuspensedView>
              <ExchangeRatePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/invoices/*'
          element={
            <SuspensedView>
              <InvoicePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/refunds/*'
          element={
            <SuspensedView>
              <RefundPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/payment-plan-orders/*'
          element={
            <SuspensedView>
              <PaymentPlanOrdersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/website/flight-deals/*'
          element={
            <SuspensedView>
              <TravelDealsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/website/tours/*'
          element={
            <SuspensedView>
              <ToursPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/website/blog-articles/*'
          element={
            <SuspensedView>
              <BlogArticlesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/order/flight-deal-orders/*'
          element={
            <SuspensedView>
              <FlightDealOrdersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/order/tour-orders/*'
          element={
            <SuspensedView>
              <TourOrdersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {PrivateRoutes};
