import React, {useEffect, useRef} from 'react';
import ApexCharts, {ApexOptions} from 'apexcharts';

import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils';
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';

interface QuarterlyInvoiceChartProps {
  className?: string;
  chartColor: string;
  chartHeight: string;
  title: string;
  totalAmount: number;
  labels: string[];
  dataPoints: number[];
  isProfitChart: boolean; // New prop to determine if it’s a profit or ticket chart
}

const QuarterlyInvoiceChart: React.FC<QuarterlyInvoiceChartProps> = ({
  className = '',
  chartColor,
  chartHeight,
  title,
  totalAmount,
  labels,
  dataPoints,
  isProfitChart,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const {mode} = useThemeMode();

  const getChartOptions = (): ApexOptions => {
    const labelColor = getCSSVariableValue('--kt-gray-500');
    const borderColor = getCSSVariableValue('--kt-gray-200');
    const secondaryColor = getCSSVariableValue('--kt-gray-300');
    const baseColor = getCSSVariableValue('--kt-' + chartColor);

    return {
      series: [
        {
          name: title,
          data: dataPoints,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
          borderRadius: 5,
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: labels,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        type: 'solid',
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return isProfitChart ? `$${val} revenue` : `${val} tickets`; // Conditional formatting
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        padding: {
          top: 10,
        },
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };
  };

  const refreshChart = () => {
    if (!chartRef.current) return;

    const chart = new ApexCharts(chartRef.current, getChartOptions());
    chart.render();

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();
    return () => {
      if (chart) chart.destroy();
    };
  }, [chartRef, mode, dataPoints, labels]);

  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bold text-gray-800 d-block fs-3'>{title}</span>
            <span className='text-gray-400 fw-semibold'>Current Quarter</span>
          </div>
          <div className={`fw-bold fs-3 text-${chartColor}`}>
            {isProfitChart ? `$${totalAmount}` : totalAmount}
          </div>
        </div>
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
      </div>
    </div>
  );
};

export default QuarterlyInvoiceChart;
