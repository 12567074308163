import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { OrgChartNode } from './_models';
import { getOrgChartData } from './_requests';

const departmentColors: { [key: string]: string } = {
  'After Hours': '#4e79a7',
  'Call Centre': '#f28e2c',
  'CMB Sales': '#e15759',
  'Customer Relations': '#76b7b2',
  Executive: '#59a14f',
  'Front Office': '#9c755f',
  'Group Finance': '#edc948',
  'Group HR & Admin': '#bab0ab',
  Holidays: '#b07aa1',
  HR: '#ff9da7',
  Marketing: '#76b7b2',
  'MEL Call Centre': '#59a14f',
  'MEL Office': '#ff9da7',
  Online: '#4e79a7',
  Operations: '#f28e2c',
  'Outsourced Accounting': '#edc948',
  'Outsourced Technology': '#bab0ab',
  Sales: '#e15759',
  'Sales Support': '#76b7b2',
};

export const OrgChartComponent: React.FC = () => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [orgData, setOrgData] = useState<OrgChartNode | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const fetchedData = await getOrgChartData();
        setOrgData(fetchedData);
      } catch (error) {
        console.error('Error fetching organizational chart data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!svgRef.current || !orgData) return;

    const svgElement = svgRef.current;
    const containerWidth = svgElement.clientWidth;
    const containerHeight = svgElement.clientHeight;

    const chartWidth = 14000;
    const chartHeight = 1500;
    const margin = { top: 300, right: 300, bottom: 300, left: 300 };

    const svg = d3
      .select(svgRef.current)
      .attr('width', '100%')
      .attr('height', '100%')
      .style('background-color', 'white');

    svg.selectAll('*').remove();

    const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    const zoom = d3
      .zoom<SVGSVGElement, unknown>()
      .scaleExtent([0.05, 1.5])
      .on('zoom', (event) => {
        g.attr('transform', event.transform);
      });

    svg.call(zoom);

    const root = d3.hierarchy<OrgChartNode>(orgData);

    const treeLayout = d3
      .tree<OrgChartNode>()
      .size([chartWidth - margin.left - margin.right, chartHeight - margin.top - margin.bottom])
      .separation((a, b) => (a.parent === b.parent ? 40 : 60));

    const treeData = treeLayout(root);

    g.selectAll('.link')
      .data(treeData.links())
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr(
        'd',
        d3
          .linkVertical<d3.HierarchyPointLink<OrgChartNode>, d3.HierarchyPointNode<OrgChartNode>>()
          .x((d) => d.x)
          .y((d) => d.y)
      )
      .attr('fill', 'none')
      .attr('stroke', '#ccc');

    const node = g
      .selectAll('.node')
      .data(treeData.descendants())
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d) => `translate(${d.x},${d.y})`);

    node
      .append('circle')
      .attr('r', 16)
      .attr('fill', (d) => {
        if (d.depth === 0) return '#333';
        if (d.depth === 1) {
          return departmentColors[d.data.name as keyof typeof departmentColors] || '#999';
        }
        return '#fff';
      })
      .attr(
        'stroke',
        (d) =>
          d.depth > 1
            ? departmentColors[d.parent?.data.name as keyof typeof departmentColors] || '#999'
            : '#333'
      );

    node
      .append('text')
      .attr('dy', '.35em')
      .attr('y', (d) => (d.children ? -60 : 60))
      .style('text-anchor', 'middle')
      .text((d) => d.data.name)
      .style('font-weight', (d) => (d.depth <= 1 ? 'bold' : 'normal'))
      .call(wrap, 800);

    node
      .append('text')
      .attr('dy', '.35em')
      .attr('y', (d) => (d.children ? -90 : 90))
      .style('text-anchor', 'middle')
      .text((d) => d.data.title || '')
      .style('font-size', '0.8em')
      .style('fill', '#555')
      .call(wrap, 800);

    function wrap(
      selection: d3.Selection<
        SVGTextElement,
        d3.HierarchyPointNode<OrgChartNode>,
        SVGGElement,
        unknown
      >,
      width: number
    ) {
      selection.each(function (this: SVGTextElement) {
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word;
        let line: string[] = [];
        let lineNumber = 0;
        const lineHeight = 1.5;
        const y = text.attr('y');
        const dy = parseFloat(text.attr('dy') || '0');
        let tspan = text
          .text(null)
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', dy + 'em');

        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(' '));
          if ((tspan.node()?.getComputedTextLength() || 0) > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = text
              .append('tspan')
              .attr('x', 0)
              .attr('y', y)
              .attr('dy', ++lineNumber * lineHeight + dy + 'em')
              .text(word);
          }
        }
      });
    }

    const scaleX = containerWidth / chartWidth;
    const scaleY = containerHeight / chartHeight;
    const initialScale = Math.min(scaleX, scaleY, 0.35); // Increased initial scale for zoomed-in effect

    const initialX = (containerWidth - chartWidth * initialScale) / 2;
    const initialY = 0; // Set Y translation to zero to keep the top node visible

    const initialTransform = d3.zoomIdentity.translate(initialX, initialY).scale(initialScale);
    svg.call(zoom.transform, initialTransform);
  }, [orgData]);

  return (
    <>
      {loading ? (
        <div>Loading organizational chart...</div>
      ) : orgData ? (
        <svg ref={svgRef} style={{ width: '100%', height: '100%' }}></svg>
      ) : (
        <div>No organization data available</div>
      )}
    </>
  );
};
